import React from 'react'
import Header from './Header'
import Footer1 from './Footer1'
import { ImgProvider } from '../Assest/ImgProvider'
export default function Contactus() {
  return (
    <>
      <Header />

      <div className='banner menulaybanner'>
        <div>
          <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} />
        </div>
        <div>
          <div className='headting'>Contact Us</div>
          <div className='subhedting'>"Get in touch for any assistance"</div>
        </div>
        <div> <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} /></div>
      </div>
      <div className='contactmap'>
      <div className='conatctmanis'>
        <div className="constctbox mt-20 mb-20 ">
          <div className="grid  py-8 text-lg leading-6 text-gray-800 md:gap-8 md:grid-cols-12">
            <div className="space-y-3 col-span-4 contactleftbox mt-5 mb-5">
              <div className='subhedting1' style={{fontSize:"32px",lineHeight:"30px"}}>Company:  VOTARINI LTD</div>
              <div style={{wordWrap:"break-word"}}><i className="fa fa-map" /> Griva Digeni, 115, Trident Centre 3101, Limassol, Cyprus</div>
              <div style={{wordWrap:"break-word"}}><i className="fa fa-envelope" /> support@YogaRise.comm</div>
              <div><i className="fa fa-phone" /> +3356 1589 2105</div>
              <div><i className="fa fa-phone" /> +3356 1589 2100</div>
            </div>
            <div className="space-y-3  col-span-8">
              <div className="mt-5 mb-5  ">
                <div className='formlau'>
                  <div className='subhedting1'>Get in Touch</div>
                  <div>Feel free to drop us a line below!</div>
                  <form >
                    <div className="mt-5 mb-5">
                      <input type='text' name='name' placeholder='Your Full Name' required className='inputbox' />
                    </div>
                    <div className="mb-5">
                      <input type='email' name='email' placeholder='Your Email Id' required className='inputbox' />
                    </div>
                    <div className=" mb-5">
                      <input type='textarea' name='message' placeholder='Type your message here...' required className='inputbox' />
                    </div>
                    <div className='btnlay1 pppp' >Send</div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <Footer1 />
    </>
  )
}
