import React from 'react'
import Header from './Header'
import Footer1 from './Footer1'
import { ImgProvider } from '../Assest/ImgProvider'
export default function Services() {
  const services = [
    {
      id: 0,
      title: "Hatha Yoga",
      description: "Hatha Yoga focuses on physical postures and breath control, promoting balance, flexibility, and strength while calming the mind and improving overall well-being. Perfect for all skill levels.",
      imgSrc: ImgProvider?.service0,
    },
    {
      id: 1,
      title: "Vinyasa Yoga",
      description: "Vinyasa Yoga emphasizes a fluid sequence of poses linked with breath, building strength, flexibility, and mindfulness through continuous movement. Suitable for those seeking a dynamic, flowing practice.",
      imgSrc: ImgProvider?.service1,
    },
    {
      id: 2,
      title: "Ashtanga Yoga",
      description: "Ashtanga Yoga follows a structured sequence of poses combined with breath, building strength, flexibility, and discipline. Ideal for those seeking a vigorous, consistent practice.",
      imgSrc: null,
    },
    {
      id: 3,
      title: "Yin Yoga",
      description: "Yin Yoga involves holding passive poses for extended periods, targeting deep connective tissues to enhance flexibility and promote relaxation. Perfect for relieving stress and improving joint mobility.",
      imgSrc: ImgProvider?.service3,
    },
    {
      id: 4,
      title: "Restorative Yoga",
      description: "Restorative Yoga focuses on gentle poses supported by props, promoting deep relaxation and healing. Ideal for stress relief, recovery, and restoring balance to the body and mind.",
      imgSrc: null,
    },
    {
      id: 5,
      title: "Dynamic Yoga",
      description: "Dynamic Yoga combines flowing movements with breath, building strength, flexibility, and endurance. Ideal for those seeking an energetic, full-body workout with mindful focus.",
      imgSrc: ImgProvider?.service5,
    },
    {
      id: 6,
      title: "Kundalini Yoga",
      description: "Kundalini Yoga blends physical postures, breathwork, and meditation to awaken energy and enhance spiritual awareness. Ideal for those seeking mental clarity, inner peace, and personal growth.",
      imgSrc: null,
    },
    {
      id: 7,
      title: "Ariel Yoga",
      description: "Aerial Yoga uses a suspended hammock to support and enhance traditional poses, improving flexibility, strength, and balance while adding an element of fun and creativity. Ideal for exploring movement in a new way.",
      imgSrc: null,
    },
    {
      id: 8,
      title: "Prenatal Yoga",
      description: "Prenatal Yoga offers gentle, safe poses tailored for expecting mothers, promoting relaxation, strength, and flexibility while supporting physical and emotional well-being throughout pregnancy. Ideal for preparing the body and mind for childbirth.",
      imgSrc: null,
    },
    {
      id: 9,
      title: "Breathwork Yoga",
      description: "Breathwork Yoga integrates breathing techniques with yoga poses to enhance energy flow, reduce stress, and improve mental focus. Ideal for deepening your practice and achieving greater relaxation.",
      imgSrc: ImgProvider?.service9,
    },
    {
      id: 10,
      title: "Meditation",
      description: "Meditation involves focused practices to calm the mind, reduce stress, and enhance mental clarity. Ideal for cultivating inner peace, mindfulness, and emotional balance.",
      imgSrc: ImgProvider?.service10,
    },
  ];
  return (
    <>
      <Header />
      <div className='banner menulaybanner'>
        <div>
          <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} />
        </div>
        <div>
          <div className='headting'>Our services</div>
          <div className='subhedting'>Which we are providing</div>
        </div>
        <div> <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} /></div>
      </div>
      <div className='serivd'>
        <div className="max-w-7xl px-2 py-3 mx-auto mt-10 tracking-wide md:px-4 md:mt-10">
          {services.map((service, index) => (
            <div key={service.id}>
              {index % 2 === 0 ? (
                <div className="grid gap-3 py-8 text-lg leading-6 text-gray-800 md:gap-8 md:grid-cols-12">
                  <div className="space-y-3 col-span-4 flexweb1">
                    <div style={{ height: "auto", width: "100%" }}>
                      {
                        service?.imgSrc === null ?
                          <div style={{ display: "none" }}> </div>
                          :
                          <img src={service.imgSrc} alt="servicebnner" />
                      }
                    </div>
                  </div>
                  <div className="space-y-3 col-span-8 flexweb1 " >
                    <div className="headting">{service.title}</div>
                    <div className="servicsectionboxpara" style={{ textAlign: "left" }}>{service.description}</div>
                  </div>
                </div>
              ) : (
                <div className="grid gap-3  py-8 text-lg leading-6 text-gray-800 md:gap-8 md:grid-cols-12">
                  <div className="space-y-3 col-span-8 flexweb1">
                    <div className="headting">{service.title}</div>
                    <div className="servicsectionboxpara" style={{ textAlign: "left" }}>{service.description}</div>
                  </div>
                  <div className="space-y-3 col-span-4 flexweb1">
                    <div style={{ height: "auto", width: "100%" }}>
                      {
                        service?.imgSrc === null ?
                          <div style={{ display: "none" }}> </div>
                          :
                          <img src={service.imgSrc} alt="servicebnner" />
                      }
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer1 />
    </>
  )
}
