import React, { useEffect, useState, useRef } from 'react';
import { ImgProvider } from '../Assest/ImgProvider';
import { Link, useLocation } from 'react-router-dom';
import { App_URL, Plan_URL } from '../apiConfge';

export default function Header() {
  const location = useLocation();
  const menuRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const id = location?.hash;
    const idname = id.replace("#", "");
    scrollTo(idname);
  }, [location]);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className='hederbox'>
      <div className="max-w-7xl " style={{ margin: "auto" }}>
        <nav className="meuuuu" ref={menuRef} >
          <div className=' grid gap-3    md:grid-cols-12' style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="navbar-brand col-span-1">
              <Link to="/" onClick={() => scrollTo('home')}>
                <img src={ImgProvider.logo} alt='logo' height={36} width={100} />
              </Link>
            </div>
            <div className='col-span-7 melist ' style={{ marginRight: "-2rem" }}>
              <div className={`menu ${isOpen ? "open" : ""}`}>
                <div className="space-y-1">
                  <Link to="/#about" onClick={() => scrollTo('about')}>
                    <div className='menutext'>Why Choose Us</div>
                  </Link>
                </div>
                <div className="space-y-1">
                  <Link to="/#services" onClick={() => scrollTo('services')}>
                    <div className='menutext'>Our Services</div>
                  </Link>
                </div>
                <div className="space-y-1">
                  <Link to="/#app" onClick={() => scrollTo('app')}>
                    <div className='menutext'>Our App</div>
                  </Link>
                </div>
                <div className="space-y-1">
                  <Link to="/contact">
                    <div className='menutext'>Contact Us</div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-span-4 melist'>
              <div className="space1">
                <Link to={App_URL}>
                  <div className='textallm'>Already a member</div>
                </Link>
              </div>
              <div className="space1">
                <Link to={Plan_URL}>
                  <div className='btnlay'>Register <i className="fa fa-arrow-right" style={{ color: "#fff", fontSize: "12px" }} /></div>
                </Link>
              </div>
            </div>
          </div>
          <div className="menu-toggle" onClick={toggleMenu}> ☰ <Link smooth={true} to={App_URL}><div className='textallm'> Already a member </div></Link> </div>
        </nav>
      </div>
    </div>
  );
}
