import React from 'react'
import Header from './Header'
import Footer1 from './Footer1'
import { ImgProvider } from '../Assest/ImgProvider'
export default function Aboutus() {
  const Aboutus = [
    {
      id: 0,
      title: "Our Story",
      description: "At the heart of our app is a passion for holistic well-being. We believe that wellness is a journey that encompasses not just physical fitness but also mental and emotional health. Our platform was created to make yoga, meditation, and mindfulness practices accessible to everyone, no matter where they are on their wellness path. Whether you’re a beginner or an experienced practitioner, our app provides a supportive, flexible, and personalized experience to help you achieve your goals.",
      imgSrc: ImgProvider?.about,
    },
    {
      id: 1,
      title: "Our Mission",
      description: "Our mission is to empower individuals to live healthier, more balanced lives. Through the practice of yoga, meditation, and mindful living, we aim to foster a sense of connection between mind, body, and spirit. We’re committed to creating an inclusive and accessible space where users of all experience levels can explore and deepen their practice.",
      imgSrc: ImgProvider?.about1,
    },
    {
      id: 2,
      title: "What We Offer",
      description: "A variety of yoga styles, including Hatha, Vinyasa, Ashtanga, and Yin Yoga, tailored to different needs and levels.Guided meditation sessions to help calm the mind, reduce stress, and promote inner peace.Personalized wellness plans that adjust to your unique goals, whether it’s increasing flexibility, building strength, or finding relaxation.Easy access to on-demand and live classes, allowing you to practice anytime, anywhere.A supportive community of like-minded individuals, all on a shared journey towards well-being",
      imgSrc: ImgProvider?.about2,
    },
    {
      id: 3,
      title: "Why Choose Us",
      description: "At our core, we offer a personalized and holistic approach to wellness, with expert instructors guiding you through every step of your journey. From tailored yoga and meditation routines to nutritional advice, we cater to your unique goals and lifestyle. Our app provides easy access to on-demand classes, live sessions, and progress tracking, all available anytime, anywhere. With a focus on building a supportive community, we are committed to helping you achieve lasting physical and mental well-being. Choose us for professional guidance, flexibility, and a transformative wellness experience.",
      imgSrc: ImgProvider?.about3,
    },
  ];
  return (
    <>
      <Header />
      <div className='banner menulaybanner'>
        <div>
          <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} />
        </div>
        <div>
          <div className='headting'>About Us</div>
          <div className='subhedting'>Empowering your wellness journey with expertise.</div>
        </div>
        <div> <img src={ImgProvider?.servicebnner} alt='servicebnner' height={125} width={105} /></div>
      </div>
      <div className='serivd'>
        <div className="max-w-7xl px-2 py-3 mx-auto mt-10  md:px-4 md:mt-10">
          {Aboutus.map((service, index) => (
            <div key={service.id}>
              {index % 2 === 0 ? (
                <div className="grid gap-3 py-8   md:grid-cols-12">
                  <div className="space-y-3 col-span-4 flexweb1">
                    <img src={service.imgSrc} alt="servicebnner" style={{ height: "auto", width: "100%" }} />
                  </div>
                  <div className="space-y-3  col-span-8 flexweb1 " >
                    <div className="headting">{service.title}</div>
                    <div className="servicsectionboxpara" style={{ textAlign: "left" }}>{service.description}</div>
                  </div>
                </div>
              ) : (
                <div className="grid gap-3  py-8   md:grid-cols-12">
                  <div className="space-y-3 col-span-8 flexweb1 " >
                    <div className="headting">{service.title}</div>
                    <div className="servicsectionboxpara" style={{ textAlign: "left" }}>{service.description}</div>
                  </div>
                  <div className="space-y-3 col-span-4 flexweb1">
                    <img src={service.imgSrc} alt="servicebnner" style={{ height: "auto", width: "100%" }} />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer1 />
    </>
  )
}
