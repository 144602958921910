import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import { ImgProvider } from '../../Assest/ImgProvider';
import { Autoplay, Pagination } from 'swiper/modules';
// Import Swiper modules
// import { Navigation } from 'swiper/modules';
const TestimonialsSection = () => {
  const multiplier = {
    translate: 0.3,
    rotate: 0.03,
  };
  const testimonials = [
    {
      name: "Emma W.",
      rating: ImgProvider?.rating,
      image: ImgProvider?.testclient,
      review: "YogaRise has been a game-changer for my wellness journey! The customized routines align perfectly with my hectic day, and the meditations help me stay grounded. Highly recommended for anyone aiming to elevate their body and mind!"
    },
    {
      name: "Sophia L.",
      rating: ImgProvider?.rating,
      image: ImgProvider?.testclient1,
      review: "Absolutely love the personalized sessions! They’ve helped me fit yoga into my busy lifestyle and stay consistent. The meditations are amazing for unwinding after a long day!"
    },
    {
      name: "Daniel K.",
      rating: ImgProvider?.rating,
      image: ImgProvider?.testclient2,
      review: "This app has transformed my yoga practice! The personalized plans fit my busy schedule, and I love the guided meditations. Highly recommend for anyone looking to improve their physical and mental well-being."
    },
    {
      name: "Lily P.",
      rating: ImgProvider?.rating,
      image: ImgProvider?.testclient,
      review: "This app has completely transformed how I approach yoga. The instructors are so encouraging, and the classes are structured just right. My flexibility and focus have improved so much!"
    },
    {
      name: "Alex R.",
      rating: ImgProvider?.rating,
      image: ImgProvider?.testclient1,
      review: "Incredible app! The custom yoga plans have made it so easy to get started, and I already feel more centered and relaxed. A must-have for anyone wanting to make wellness a priority."
    },
    {
      name: "Sophia L.",
      rating: ImgProvider?.rating,
      image: ImgProvider?.testclient1,
      review: "Absolutely love the personalized sessions! They’ve helped me fit yoga into my busy lifestyle and stay consistent. The meditations are amazing for unwinding after a long day!"
    },
    {
      name: "Daniel K.",
      rating: ImgProvider?.rating,
      image: ImgProvider?.testclient2,
      review: "This app has transformed my yoga practice! The personalized plans fit my busy schedule, and I love the guided meditations. Highly recommend for anyone looking to improve their physical and mental well-being."
    },
  ];
  useEffect(() => {
    const calculateWheel = () => {
      const slides = document.querySelectorAll(".single");
      slides.forEach((slide) => {
        const rect = slide.getBoundingClientRect();
        const r = window.innerWidth * 0.5 - (rect.x + rect.width * 0.5);
        let ty = Math.abs(r) * multiplier.translate - rect.width * multiplier.translate;

        if (ty < 0) {
          ty = 0;
        }
        const transformOrigin = r < 0 ? "left top" : "right top";
        slide.style.transform = `translate(0, ${ty}px) rotate(${-r * multiplier.rotate}deg)`;
        slide.style.transformOrigin = transformOrigin;
      });
    };

    const raf = () => {
      requestAnimationFrame(raf);
      calculateWheel();
    };

    raf();
  }, []);

  return (
    <div id="testimonial" className="textmonbox">
      <div className=" max-w-8xl  mt-10 mb-20  mx-auto   md:px-4  " >
        <h2 className='subhedting1 mb-5 mt-5' style={{ textAlign: "center" }}>What our clients say</h2>
        <Swiper
          slidesPerView="auto"
          spaceBetween={120}
          centeredSlides={true}
          loop={true}
          pagination={{
            dynamicBullets: true,
            type: "progressbar",
          }}
          modules={[Autoplay, Pagination]}
          grabCursor={true}
          autoplay={{ delay: 5000 }} className="mt-10 mb-10">
          {testimonials?.map((item, i) => (
            <SwiperSlide key={i} className="swiper-slide ">
              <div className="single  mb-5 mt-5 ">
                <div className="textboxman">
                  <img src={item?.image} alt={item.name} className="clientimage" />
                  <div>
                    <h3 className='testcontent'> {item.name}</h3>
                    <div className="testrating"><img src={item.rating} alt={item.name} /> </div>
                  </div>
                </div>
                <div className="testcontent mt-5 mb-5">{item?.review.slice(0, 150)}</div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default TestimonialsSection;
