import React from "react";
import { useNavigate } from "react-router-dom";
import { ImgProvider } from "../Assest/ImgProvider";
export default function NoPage() {
  const navigate = useNavigate();
  return (
    <div className="nopagebodylayout nopageloder">
      <div>
      <div> <img src={ImgProvider.nopage} alt="notpage" width={"100%"} /> </div>
      <div className="nohedeing mt-5 mb-5">I cannot find the file you requested!</div>
      <div className="nohedeing mt-5 mb-5">the page you are looking for not avaible!</div>
      <div className="buttonlayout mt-5 mb-5" style={{maxWidth:"310px"}} onClick={() => navigate("/")}>Go Back Home</div>
      </div>
    </div>
  );
}
