import React from 'react'
import { ImgProvider } from '../../Assest/ImgProvider';
export default function ServiceSection() {
  const services = [
    {
      title: "Hatha Yoga",
      description: "Hatha Yoga balances the mind and body through...",
      image: ImgProvider?.homeservice,
    },
    {
      title: "Vinyasa Yoga",
      description: "Hatha Yoga balances the mind and body through physical postures, breathing techniques...",
      image: ImgProvider?.homeservice1,
    },
    {
      title: "Yin Yoga",
      description: "Meditation calms the mind and body, fostering...",
      image: ImgProvider?.homeservice3,
    },
    {
      title: "Meditation",
      description: "Hatha Yoga balances the mind and body through physical postures, breathing techniques...",
      image: ImgProvider?.homeservice2,
    },
    {
      title: "Meditation yoga",
      description: "Hatha Yoga balances the mind and body through physical...",
      image: ImgProvider?.homeservice4,
    },
    {
      title: "Meditation rise",
      description: "Hatha Yoga balances the mind and body through physical postures, breathing techniques...",
      image: ImgProvider?.homeservice5,
    },


  ];
  return (
    <div id="services" className="seervicsectionback">
      <div className=" max-w-7xl px-3 mx-auto md:px-4 ">
        <h2 className=' flex  text-3xl  md:px-4 mb-5  subhedting1' >Our Services</h2>
        <div className="servicessectboxmain items-center justify-between grid gap-3 lg:grid-cols-12 xs:grid-cols-12 sm:grid-cols-12 md:grid-cols-12">
          <div className='lg:col-span-2 md:col-span-2 sm:col-span-4 xs:col-span-12'>
            <div className="servicsectionbox">
              {/* <img src={ImgProvider?.secvucesection1} alt={service?.title} className="card-image" /> */}
              <img src={services[0]?.image} alt={services[0]?.title} className="card-image" />
              <div className="card-overlay px-3">
                <h2 className='servicsectionboxhead mt-2 mb-2'>{services[0]?.title}</h2>
                <p className="servicsectionboxpara mb-2  "> {services[0]?.description?.slice(0, 50)} </p>
              </div>
            </div>
          </div>
          <div className='lg:col-span-2 md:col-span-2 sm:col-span-4 xs:col-span-12'>
            <div className='servicsectionbox mt-20'>
              <h3 className='servicsectionboxhead'>{services[1]?.title}</h3>
              <p className='servicsectionboxpara mt-2 mb-5'>{services[1]?.description?.slice(0, 50)}</p>
              <img src={services[1]?.image} alt={services[1]?.title} className='servicesectionimg' />
              {/* <img src={ImgProvider?.secvucesection1} alt={service?.title} className='servicesectionimg' /> */}
            </div>
          </div>
          <div className='lg:col-span-2 md:col-span-2 sm:col-span-4 xs:col-span-12'>
            <div className='servicsectionbox mt-20'>
              <img src={services[2]?.image} alt={services[2]?.title} className='servicesectionimg  mb-5' />
              <h3 className='servicsectionboxhead'>{services[2]?.title}</h3>
              <p className='servicsectionboxpara '>{services[2]?.description?.slice(0, 50)}</p>
              {/* <img src={ImgProvider?.secvucesection1} alt={service?.title} className='servicesectionimg' /> */}
            </div>
          </div>
          <div className='lg:col-span-2 md:col-span-2 sm:col-span-4 xs:col-span-12'>
            <div className="servicsectionbox">
              {/* <img src={ImgProvider?.secvucesection1} alt={service?.title} className="card-image" /> */}
              <img src={services[3]?.image} alt={services[3]?.title} className="card-image" />
              <div className="card-overlay px-3">
                <h2 className='servicsectionboxhead mt-2 mb-2'>{services[3]?.title}</h2>
                <p className="servicsectionboxpara mb-2  "> {services[3]?.description?.slice(0, 50)} </p>
              </div>
            </div>
          </div>
          <div className='lg:col-span-2 md:col-span-2 sm:col-span-4 xs:col-span-12'>
            <div className='servicsectionbox mt-20'>
              <h3 className='servicsectionboxhead'>{services[4]?.title}</h3>
              <p className='servicsectionboxpara mt-2 mb-5'>{services[4]?.description?.slice(0, 50)}</p>
              <img src={services[4]?.image} alt={services[4]?.title} className='servicesectionimg' />
              {/* <img src={ImgProvider?.secvucesection1} alt={service?.title} className='servicesectionimg' /> */}
            </div>
          </div>
          <div className='lg:col-span-2 md:col-span-2 sm:col-span-4 xs:col-span-12'>
            <div className='servicsectionbox mt-20'>
              <img src={services[5]?.image} alt={services[5]?.title} className='servicesectionimg  mb-5' />
              <h3 className='servicsectionboxhead'>{services[5]?.title}</h3>
              <p className='servicsectionboxpara '>{services[5]?.description?.slice(0, 50)}</p>
              {/* <img src={ImgProvider?.secvucesection1} alt={service?.title} className='servicesectionimg' /> */}
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
